import React, { Component } from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";
import BrandingList from "./BrandingList";

interface BrandingState {
  showModal: boolean;
  images: { id: string; file: { url: string } }[];
}

interface BrandingProps {
  footerContent: any;
  brands: any;
}

export default class Branding extends Component<BrandingProps, BrandingState> {
  render(): JSX.Element {
    const { footerContent, brands } = this.props;

    return (
      <Layout contentfulFooterContentForPages={footerContent}>
        <BreadCrumb
          activePath={{ routeName: "Zartek Branding" }}
          routingPaths={[{ path: "/", routeName: "Home" }]}
          title="Zartek Branding"
        />
        <BrandingList brands={brands} />
      </Layout>
    );
  }
}
