import React, { FC, ReactNode } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#___gatsby");

interface ModalComponentProps {
  isOpen: boolean;
  setShowModal: any;
  children: ReactNode;
}

export const ModalComponent: FC<ModalComponentProps> = ({ children, isOpen, setShowModal }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setShowModal(false)}
      style={{
        content: {
          zIndex: 15,
          padding: 0,
          margin: "auto",
          height: "fit-content",
          width: "fit-content",
        },
        overlay: {
          zIndex: 15,
          padding: 0,
        },
      }}
    >
      <i
        className="linearicons-cross modal-close"
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        onClick={() => setShowModal(false)}
      ></i>
      {children}
    </ReactModal>
  );
};
