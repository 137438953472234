import Branding from "@/components/Branding";
import SEO from "@/components/SEO";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const BrandingPage: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query BrandingPage {
        contentfulBrandingPage {
          #     SEO
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          brandings {
            brands {
              id
              brandTitle
              icon {
                file {
                  url
                }
              }
              detailedImages {
                id
                file {
                  url
                }
              }
            }
          }

          #     FOOTER
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulBrandingPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        footerContent,
        brandings: { brands },
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <Branding footerContent={footerContent} brands={brands} />
        </Fragment>
      );
    }}
  />
);

export default BrandingPage;
