import React, { Component } from "react";
import { LazyImage } from "../Helpers/LazyImage";
import { CarouselComponent } from "../Shared/Carousel";
import { ModalComponent } from "../Shared/Modal";

interface BrandingState {
  showModal: boolean;
  images: { id: string; file: { url: string } }[];
}

export default class BrandingList extends Component<{ brands: any }, BrandingState> {
  state: BrandingState = {
    showModal: false,
    images: [],
  };

  closeModal = (): void => {
    this.setState({
      showModal: false,
    });
  };

  render(): JSX.Element {
    const { brands } = this.props;

    return (
      <section className="section section-lg pb-3">
        <div className="container">
          <div className="row row-50 row-xl-70">
            {brands.map(
              ({
                id,
                brandTitle,
                icon: {
                  file: { url: brandico },
                },
                detailedImages,
                description,
              }) => {
                return (
                  <div className="col-sm-6 col-lg-4 col-xl-3" key={id}>
                    <article className="profile-modern">
                      <figure className="profile-modern-figure">
                        <div className="profile-modern-image">
                          <figure className="profile-modern-figure">
                            <LazyImage height={264} width={264} src={brandico} alt="portfolio" />
                          </figure>
                        </div>
                      </figure>
                      <div className="profile-modern-main">
                        <div className="profile-modern-main-item profile-modern-main-item_primary">
                          <h4 className="profile-modern-name">{brandTitle}</h4>
                        </div>
                        <div className="profile-modern-main-item profile-modern-main-item_secondary">
                          <ul className="list-inline list-inline-xs">
                            <li
                              onClick={() => {
                                this.setState({
                                  images: detailedImages,
                                  showModal: true,
                                });
                              }}
                            >
                              <i
                                style={{ cursor: "pointer" }}
                                className="linearicons-file-preview"
                              />
                            </li>
                          </ul>
                          <div className="profile-modern-toggle mdi mdi-arrow-top-right" />
                        </div>
                      </div>
                    </article>
                  </div>
                );
              },
            )}
          </div>
        </div>
        <ModalComponent isOpen={this.state.showModal} setShowModal={this.closeModal}>
          <CarouselComponent images={this.state.images} />
        </ModalComponent>
      </section>
    );
  }
}
