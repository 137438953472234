import React, { FC, useState } from "react";

interface CarouselComponentProps {
  images: { id: string; file: { url: string } }[];
}

export const CarouselComponent: FC<CarouselComponentProps> = ({ images }) => {
  const [active, setActive] = useState(0);
  const [touchStartPos, setTouchStartPos] = useState(null);

  const handleNext = () => {
    if (active === images.length - 1) {
      setActive(active);
    } else {
      setActive((current) => (current += 1));
    }
  };

  const handlePrev = () => {
    if (active === 0) {
      setActive(active);
    } else {
      setActive((current) => (current -= 1));
    }
  };

  const handeSwipe = (e) => {
    if (e.changedTouches[0].clientX > touchStartPos) {
      handlePrev();
    } else {
      handleNext();
    }
  };

  return (
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {images.map(({ id, file: { url } }, i) => {
          return (
            <div
              onTouchStart={(e) => setTouchStartPos(e.touches[0].clientX)}
              onTouchEnd={handeSwipe}
              className={`carousel-item ${active === i && "active"}`}
              key={id}
            >
              <img src={url} alt={id} />
            </div>
          );
        })}
      </div>

      <a
        onClick={(e) => {
          e.preventDefault();
          handlePrev();
        }}
        className="carousel-control-prev bts-ctrl"
        role="button"
        href="/"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          handleNext();
        }}
        className="carousel-control-next bts-ctrl"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};
